// settings
@import "settings/mixins";
@import "settings/colors";
@import "settings/fonts";
@import "settings/icons";
@import "settings/typography";
@import "settings/variables";

.cpbulba-sector-related {
    background: $background-color2;
    padding: 3rem 2rem;
    @include media("tablet", max) {
        padding: 2rem 1rem;
    }
    .wrapper {
        max-width: 1280px;
        margin: auto;
        > h2{
            display: flex;
            align-items: center;
            text-transform: uppercase;
            font-size: .85em;
            margin-bottom: 2rem;
            border-bottom: 1px dotted $text-color;
            span{
                padding: .5rem;
                margin-right: .5rem;
                font-size: .75em;
            }
        }
    }
    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2rem;
        width: 100%;
        @include media("tablet-wide", max){
            margin-bottom: 1rem;
        }
        .heading {
            margin: 0;
            a {
                color: $gradient-color1;
            }
        }
        .button {
            font-size: .85rem;
            display: inline-block;
            color: $gradient-color1;
            border: 1px solid $gradient-color1;
            padding: .5rem;
            border-radius: .25rem;
            &:hover {
                background: rgba($gradient-color1, 0.1);
            }
        }
    }
    .card{
        flex: 1;
        width: 100%;
        display: flex;
        a{
            width: 100%;
            overflow: hidden;
            border-radius: 1rem;
            background: #fff;
            box-shadow:  0 1rem 1rem rgba(#000, 0.15);
            @media (min-width: #{map-get($breakpoints, 'phone-wide')}) and (max-width: #{map-get($breakpoints, 'tablet-wide')}){
                display: flex;
                .background{
                    flex-basis: 40%;
                }
                .img-wrapper{
                    flex-basis: 40%;
                }
            }
            &:hover{
                background: $gradient-color1;
                box-shadow: 0 0 0 rgba(#000, 0);
                .img-wrapper{
                    mix-blend-mode: luminosity;
                }
                .content{
                    color: $acqua;
                    .heading{
                        color: $acqua;
                        img{
                            opacity: .75;
                            mix-blend-mode: overlay;
                            background: none !important;
                        }
                    }
                }
            }
        }
        .background{
            overflow: hidden;
            position: relative;
            @include responsive-ratio(720, 405, true);
            background: $acqua;
            &:after{
                content: '';
                font-family: $cpicons;
                position: absolute;
                bottom: -3rem;
                right: -3rem;
                font-size: 15em;
                color: rgba(#000, 0.1);
                line-height: 1;
            }
            .icon{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 8em;
                color: #fff;
                font-style: normal;
                line-height: 1;
                z-index: z("bulbasaur");
                &:before{
                    content: '';
                    font-family: $cpicons;
                }
            }
        }
        .img-wrapper{
            overflow: hidden;
            position: relative;
            @include responsive-ratio(720, 405, true);
            img{
                @include full;
                overflow: hidden;
                object-fit: cover;
                transition: all 150ms ease-in-out;
            }
        }
        .content{
            flex: 1;
            padding: 2em;
            color: $text-color;
            @include media("tablet-wide", max){
                padding: 1rem;
            }
            .heading{
                display: flex;
                justify-content: space-between;
                font-size: 1.15em;
                margin-bottom: 1em;
                span{
                    flex: 1;
                }
                img{
                    margin-left: 1rem;
                    height: 2.5rem;
                    border-radius: .25rem;
                }
            }
            p:last-child{
                margin-bottom: 0;
            }
        }
    }
    .row{
        @include media("tablet-wide", min){
            display: flex;
        }
        .column{
            flex-basis: calc(100%/3);
            display: flex;
            flex-direction: column;
            /* IE ONLY */
            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                display: block;
                width: 100%;
            }
            &:not(:last-child){
                @include media("tablet-wide", min){
                    margin-right: 2rem;
                }
                @include media("tablet-wide", max){
                    margin-right: 0;
                    margin-bottom: 2rem;
                }
            }
        }
    }
    @mixin cpbulbaSectorRelatedColours($id, $color1, $color2) {
        &[data-sectorgroup="#{$id}"]{
            background: $color2;
            .wrapper > h2{
                color: $color1;
                border-color: $color1;
                span{
                    background: $color1;
                    color: $color2;
                }
            }
            .head{
                .heading{
                    a{
                        color: $color1;
                    }
                }
                .button {
                    color: $color1;
                    border: 1px solid $color1;
                    &:hover {
                        background: rgba($color1, 0.1);
                    }
                }
            }
            .card{
                a{
                    &:hover{
                        background: $color1;
                        .content{
                            color: $color2;
                            .heading{
                                color: $color2;
                            }
                        }
                    }
                }
                .background{
                    background: $color1;
                    &:after{
                        color: darken($color1, 5%);
                    }
                    .icon{
                        color: $color2;
                    }
                }
            }
        }
    }
    @each $id, $data in $sector-scheme {
        @include cpbulbaSectorRelatedColours($id, nth($data, 1), nth($data, 2));
    }
    @mixin cpbulbaSetorRelatedIcons($id, $icon) {
        &[data-sector="#{$id}"]{
            .card{
                .background{
                    &:after{
                        content: unicode($icon);
                    }
                    .icon{
                        &:before{
                            content: unicode($icon);
                        }
                    }
                }
            }
        }
    }
    @each $id, $data in $sector-icons {
        @include cpbulbaSetorRelatedIcons($id, $data);
    }
}